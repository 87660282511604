// 教务中心(教师管理)
<template>
  <div class="educational_center">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="educational_center_content">
          <!-- 顶部的操作框部分 -->
          <div class="top_info margin_top_14">
            <!-- 新增教师 -->
            <div class="com_bt_action search_bt_active margin_left_14" @click.stop="addTeacher">新增教师</div>

            <!-- 教师姓名或手机号 -->
            <div class="school_search margin_left_20">
              <span class="margin_right_4">教师：</span>
              <el-input clearable v-model="teacherTelValue" placeholder="请输入教师姓名或手机号"></el-input>
            </div>

            <!-- 学校名称 -->
            <div class="phone_search margin_left_20" v-if="role != 3">
              <span class="margin_right_4">学校名称：</span>
              <el-input clearable v-model="schoolValue" placeholder="请输入学校名称"></el-input>
            </div>

            <!-- 搜索 -->
            <!-- <div :class="[isArrowSearch?'search_bt_active':'search_bt_normal']" class="com_bt_action  margin_left_14" @click.stop="search">搜索</div> -->
          </div>

          <!-- 底部显示视图部分 -->
          <div class="show_content" v-show="!isShowEmptyView">
            <!-- 表格部分 -->
            <div class="dv_table_view">
              <div class="dv_table_view_content">
                <el-table style="width: 100%" border stripe :data="tableData" :max-height="maxHeight">
                  <!-- 序号  -->
                  <el-table-column type="index" label="序号" align="center" width="50">
                    <template scope="scope">
                      <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                    </template>
                  </el-table-column>

                  <!-- 教师姓名 -->
                  <el-table-column prop="name" label="教师姓名" width="100"></el-table-column>

                  <!-- 手机号  -->
                  <el-table-column prop="tel" label="手机号" width="125"></el-table-column>

                      <!-- 账户密码 -->
                  <el-table-column v-if="role == 4||role == 5" prop="password"  label="密码" width="100"></el-table-column>

                  <!-- 所在校区  -->
                  <el-table-column prop="school_name" :show-overflow-tooltip="true" label="所在校区" width="140"></el-table-column>

                  <!-- 校区编码  -->
                  <el-table-column prop="school_id" :show-overflow-tooltip="true" label="校区编码" width="100"></el-table-column>

                  <!-- 已开通权限课程  -->
                  <el-table-column prop="sub_name" :show-overflow-tooltip="true" label="已开通权限课程" min-width="50%"></el-table-column>

                  <!-- 班级数  -->
                  <el-table-column prop="class_num" label="班级数" width="85"></el-table-column>

                  <!-- 班级学生人数   -->
                  <el-table-column :show-overflow-tooltip="true" prop="stu_num" label="班级学生人数" width="110"></el-table-column>

                  <!--  操作 -->
                  <el-table-column label="操作" width="250">
                    <template slot-scope="scope">
                      <el-button @click="doLookAction(scope.row)" :disabled="role != 3" type="text" size="small">查看</el-button>
                      <el-button @click="doEditAction(scope.row)" :disabled="scope.row.state == -2" type="text" size="small">编辑</el-button>
                      <el-button @click="doReplaceTeacher(scope.row)" type="text" size="small">换老师</el-button>
                      <el-button @click="doUseAction(scope.row)" :disabled="scope.row.class_num != 0" type="text" size="small">删除</el-button>
                      <el-button @click="resetPwd(scope.row)" type="text" size="small">重置密码</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <!-- 顶部的分页器部分 -->
            <div class="bom_page margin_bom_20">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currPage"
                :page-sizes="pageSizeArr"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalSize"
              >
              </el-pagination>
            </div>
          </div>

          <!-- 空视图部分 -->
          <div class="show_content" v-show="isShowEmptyView">
            <EmptyView></EmptyView>
          </div>
        </div>

        <!-- 禁用的弹框 -->
        <RemoveSchoolDialog
          ref="del_item"
          v-if="showDelDialog"
          :showDialog="showDelDialog"
          @close="showDelDialog = false"
          :title="delTitle"
          @delItem="delItem"
        ></RemoveSchoolDialog>

        <!-- 新增教师的弹框 -->
        <AddTeacherDialog
          v-if="showAddTeacherDialog"
          @updatePage="updatePage"
          @close="closeAddTeacherDialog"
          :title="addTitle"
          :showDialog="showAddTeacherDialog"
        ></AddTeacherDialog>

        <!-- 编辑教师 -->
        <EditTeacherDialog
          v-if="showEditTeacherDialog"
          @updatePage="updatePage"
          @close="closeAddTeacherDialog"
          :title="editTitle"
          :showDialog="showEditTeacherDialog"
          :teacherItem="teacherItem"
        ></EditTeacherDialog>

        <!-- 替换老师的dialog -->
        <ReplaceTeacherDialog
          v-if="isShowReplaceTeacherDialog"
          @updatePage="updatePage"
          @close="isShowReplaceTeacherDialog = false"
          :isCenter="isCenter"
          :showDialog="isShowReplaceTeacherDialog"
          :width="repalceTeacherDialogWidth"
          title="提示"
          :item="teacherItem"
        ></ReplaceTeacherDialog>

        <!-- 重置密码的弹框 -->
        <ResetPwdDialog
          v-if="isShowResetPwd"
          @close="isShowResetPwd = false"
          :showDialog="isShowResetPwd"
          :resetTeacherId="resetTeacherId"
          type="2"
        ></ResetPwdDialog>
      </template>
    </Layout>
  </div>
</template>
<script>
import Layout from "@/components/Layout.vue";
//移出校区的弹框
import RemoveSchoolDialog from "@/components/project/RemoveSchoolDialog";
//新增教师的弹框
import AddTeacherDialog from "@/components/project/AddTeacherDialog";
//编辑教师的弹框
import EditTeacherDialog from "@/components/project/EditTeacherDialog";

//替换老师的dialog
import ReplaceTeacherDialog from "@/components/dialog/ReplaceTeacherDialog.vue";

//重置密码的弹框
import ResetPwdDialog from "@/components/dialog/ResetPwdDialog";
export default {
  data() {
    return {
      //是否显示替换老师的弹框
      isShowReplaceTeacherDialog: false,

      //替换老师弹框的宽度
      repalceTeacherDialogWidth: "520px",

      //是否显示加载框的标识
      showLoading: -1,

      //是否显示空视图的标识
      isShowEmptyView: false,

      //输入的老师姓名或者手机号
      teacherTelValue: "",

      //学校名称
      schoolValue: "",

      //当前页面显示的列表数据
      tableData: [],

      //总的数据条数
      totalSize: "",

      //条数选择的数据
      pageSizeArr: [6, 8, 10],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //是否显示禁用弹框的控制值
      showDelDialog: false,
      //是否显示新增教师弹框的控制值
      showAddTeacherDialog: false,

      //是否显示编辑教师弹框的控制值
      showEditTeacherDialog: false,

      //点击的教师数据
      teacherItem: null,

      //禁用标题
      delTitle: "禁用校区",

      //标题
      addTitle: "新增教师",

      editTitle: "编辑教师",
      //操作校区的id
      teacher_id: -1,
      //禁用状态传值
      disable: -1,

      //表格的最大高度
      maxHeight: 0,

      //弹框标题是居中显示
      isCenter: true,

      role: -1,

      //是否显示重置密码的弹框
      isShowResetPwd: false,

      //需要重置密码的老师id
      resetTeacherId: "",
    };
  },
  created() {
    //初始化数据
    this.initData();

    //请求页面数据
    this.httpData();
  },
  methods: {
    //刷新页面
    updatePage() {
      this.httpData();
    },
    //初始化
    initData() {
      //获取用户角色
      this.role = this.getUserRole();
      let clientH = document.documentElement.clientHeight;
      this.maxHeight = clientH - 76 - -54 - 20 - 68 - 100;
    },

    /**
     *  列表接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 1;
      param.page = this.currPage - 1;
      param.pagecount = this.pageSize;

      //老师姓名或手机号
      if (!this.isEmpty(this.teacherTelValue)) {
        param.telOrName = this.teacherTelValue;
      } else {
        param.telOrName = "";
      }
      //学校名称
      if (!this.isEmpty(this.schoolValue)) {
        param.sName = this.schoolValue;
      } else {
        param.sName = "";
      }
      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = 1;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取总条数
            this.totalSize = Number.parseInt(resulte.data.totalNum);
            if (this.totalSize != 0) {
              //证明有数据
              this.isShowEmptyView = false;

              //获取列表数据
              this.tableData = resulte.data.infoList;
            } else {
              //显示空视图
              this.isShowEmptyView = true;
            }
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = 1;
          console.log("请求数据异常");
        });
    },

    //增加教师
    addTeacher() {
      this.showAddTeacherDialog = true;
    },

    //关闭新增教师弹框
    closeAddTeacherDialog() {
      this.showAddTeacherDialog = false;
      this.showEditTeacherDialog = false;
    },

    //搜索
    search() {
      if (this.isArrowSearch) {
        //先还原页码到第一页
        this.currPage = 1;

        //刷新数据
        this.httpData();
      }
    },

    //编辑的操作
    doEditAction(item) {
      this.teacherItem = item;
      console.log(this.teacherItem);
      this.showEditTeacherDialog = true;
    },

    //换老师
    doReplaceTeacher(item) {
      this.teacherItem = item;
      console.log(this.teacherItem);
      //打开替换老师的弹框
      this.isShowReplaceTeacherDialog = true;
    },

    //移除的操作
    doUseAction(item) {
      console.log(item);
      //显示禁用的弹框
      this.showDelDialog = true;
      this.teacher_id = item.id;
      this.delTitle = "删除";
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      //重新刷新数据
      this.httpData();
    },

    //页面改变时触发
    handleCurrentChange(val) {
      this.currPage = val;
      //重新刷新数据
      this.httpData();
    },

    //老师移出校区
    async delItem() {
      let param = this.getHttpParams();
      param.type = 6;
      param.tid = this.teacher_id;

      let res = await this.$http.fetchPost(this.$api.TEACHERMANAGER, param);
      if (res.data.state == 200) {
        this.showSuccessMsg("操作成功");
        //重新刷新数据
        this.httpData();
        //关闭弹窗Loading
        this.$refs.del_item.showCommitLoading = false;
        //关闭弹框
        this.showDelDialog = false;
      } else {
        //直接将服务器返回的错误内容弹出提示
        this.showErrorMsg(this.getMesage(res.data.state));
        this.$refs.del_item.showCommitLoading = false;
      }
    },

    //查看条目
    doLookAction(item) {
      //跳转到查看课程界面
      this.$router.push({
        path: "/Home/LookTeacher",
        query: {
          tid: item.id,
          teacher_item: item,
        },
      });
    },

    //重置密码
    resetPwd(item) {
      //记录重置密码的老师id,并显示弹框
      this.resetTeacherId = item.id;
      this.isShowResetPwd = true;
    },
  },

  watch: {
    //监听输入的学校名称或者编码
    teacherTelValue(newValue, oldValue) {
      if (newValue != oldValue) {
        //还原页码到第一页
        this.currPage = 1;
        //刷新页面数据
        this.httpData();
      }
    },

    //监听输入的联系电话
    schoolValue(newValue, oldValue) {
      if (newValue != oldValue) {
        //还原页码到第一页
        this.currPage = 1;
        //刷新页面数据
        this.httpData();
      }
    },
  },

  computed: {
    //搜索框是否可以操作的控制值, 这里使用计算属性来操作
    isArrowSearch: function () {
      if (this.isEmpty(this.teacherTelValue) && this.isEmpty(this.schoolValue)) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    Layout,
    RemoveSchoolDialog,
    AddTeacherDialog,
    EditTeacherDialog,
    ReplaceTeacherDialog,
    ResetPwdDialog,
  },
};
</script>
<style lang="scss" scoped>
.educational_center {
  width: 100%;
  height: calc(100vh - 77px);
  .educational_center_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    ::v-deep .el-table .cell {
      text-align: center;
    }

    //顶部的操作栏部分
    .top_info {
      display: flex;
      align-items: center;

      //学校
      .school_search {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input__inner {
        height: 32px;
        line-height: 32px;
        padding-left: 6px;
      }

      ::v-deep .el-input__icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      ::v-deep .el-input {
        width: 210px;
      }

      ::v-deep .el-input__suffix {
        right: 0px;
      }
    }

    //内容部分
    .show_content {
      flex: 1;
      display: flex;
      height: 0;
      flex-direction: column;
      position: relative;
    }

    //表格视图
    .dv_table_view {
      flex: 1;
      margin: 4px 10px 10px;
      position: relative;
      .dv_table_view_content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    //底部的分页器部分
    .bom_page {
      display: flex;
      align-items: center;
      height: 40px;
      justify-content: center;
      // margin-bottom: 40px;
    }
  }
}
</style>
