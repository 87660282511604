//替换老师的弹框
<template>
  <div class="replace_teacher_dialog">
    <CommonDialog
      @close="close"
      :isCenter="isCenter"
      :showDialog="showDialog"
      :title="title"
      :width="width"
    >
      <template v-slot:content>
        <div class="dialog_content">
          <!-- 间隔线 -->
          <el-divider></el-divider>

          <!-- 提示语言  -->
          <div class="margin_top_30 com_dv msg_info">
            <span
              >换教师之后，该班级的所有学生将转入新教师账号名下，请谨慎操作！</span
            >
          </div>

          <!-- 原教师手机号 -->
          <div class="margin_top_30 margin_left_60">
            <span>原教师手机号：{{ tel }}</span>
          </div>

          <!-- 新教师手机号 -->
          <div class="margin_top_30 margin_left_60">
            <span>新教师手机号：</span>
            <!-- 班级名称或者班级ID -->
            <!-- <el-input clearable v-model="teacherPhone" placeholder="请输入教师手机号"></el-input> -->
            <el-select v-model="teacherId" placeholder="请选择" size="small">
              <el-option
                v-for="item in options"
                :key="item.teacher_id"
                :label="item.teacher_name+' '+item.tel"
                :value="item.teacher_id"
              >
              </el-option>
            </el-select>

            <div class="text_tip">
              此处仅显示有原老师所有课程访问权限且排课信息不冲突的新教师
            </div>
          </div>

          <!-- 替换按钮  -->
          <div class="com_dv">
            <div
              :class="[
                'commit_bt',
                isArrowSearch ? 'search_bt_active' : 'search_bt_normal',
              ]"
              class="com_bt_action"
              @click.stop="doCommitAction"
            >
              确定
            </div>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showLoading: false,
      //选中老师的ID
      teacherId: "",
      //老师集合
      options: [],
      //是否显示loading
      isShowLoading: false,

      //输入的教师手机号
      //   teacherPhone: "",

      //确定按钮是否高亮
      isArrowSearch: false,
      tel: "",
    };
  },
  created() {},
  mounted() {
    console.log(this.item);
    this.tel = this.item.tel;
    this.httpData();
  },
  methods: {
    /**
     *  可以更换老师的接口
     */
    httpData() {
      let param = this.getHttpParams();
      param.type = 5;

      if (!this.isEmpty(this.item.id)) {
        param.tid = this.item.id;
      }
      if (!this.isEmpty(this.item.tid)) {
        param.tid = this.item.tid;
      }
      if (!this.isEmpty(this.item.class_id)) {
        param.cid = this.item.class_id;
      }

      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = true;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取数据

            //可更换老师列表
            this.options = resulte.data;

            this.options.forEach((element, index) => {
              if (this.item.id == element.teacher_id) {
                this.options.splice(index, 1);
              }
            });
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = true;
          console.log("请求数据异常");
        });
    },
    //关闭的方法
    close() {
      this.$emit("close");
    },

    //确定换老师
    doCommitAction() {
      var _that = this;
    
      if(_that.isArrowSearch){
           
      
      let param = this.getHttpParams();
      param.type = 10;

      if (!this.isEmpty(this.item.id)) {
        param.tid = this.item.id;
      }
      if (!this.isEmpty(this.item.tid)) {
        param.tid = this.item.tid;
      }
      param.newTid = this.teacherId;

      if (!this.isEmpty(this.item.class_id)) {
        param.cid = this.item.class_id;
      }
      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          //显示内容视图
          this.showLoading = true;
          let resulte = res.data;
          if (resulte.state == 200) {
            //获取数据
            this.showSuccessMsg("更换成功");
            this.$emit("updatePage");
            this.$emit("close");
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          this.showLoading = true;
          console.log("请求数据异常");
        });
        }else{
          //灰色按钮
          //  this.showWarnMsg(`请选择要更好老师`);
      }
    },
    
    
  },
  computed: {},
  components: {},
  props: ["showDialog", "title", "width", "isCenter", "item"],
  watch: {
    //监听输入框值的变化
    teacherId(newValue, oldValue) {
      if (this.isEmpty(newValue)) {
        this.isArrowSearch = false;
      } else {
        this.isArrowSearch = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.replace_teacher_dialog {
  .dialog_content {
    height: 280px;
    position: relative;

    .text_tip {
      margin-top: 10px;
      margin-left: 100px;
      width: 220px;
      font-size: 12px;
    }
    .com_dv {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .msg_info {
      color: red;
    }

    .commit_bt {
      height: 32px;
      width: 120px;
      position: absolute;
      bottom: 14px;
      left: 50%;
      transform: translateX(-50%);
    }
    ::v-deep .el-input__inner {
      height: 32px;
      line-height: 32px;
      padding-left: 6px;
    }

    ::v-deep .el-input__icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ::v-deep .el-input {
      width: 210px;
    }

    ::v-deep .el-input__suffix {
      right: 0px;
    }
  }
}
</style>