// 重置密码的弹框
<template>
  <div class="reset_pwd">
    <CommonDialog @close="close" :showDialog="showDialog" title="重置密码" :width="width">
      <template v-slot:content>
        <div class="content">
          <!-- 提示信息 -->
          <div class="reset_info margin_left_10 margin_right_10 margin_top_10">
            <div>此操作会将{{ type == 1 ? "校区校长" : "老师" }}账号的登录密码重置为手机的后6位</div>
            <div class="margin_top_10">确定进行此操作吗？</div>
          </div>
          <!-- 底部的确定和取消按钮 -->
          <div class="bom margin_top_30">
            <el-button size="small" @click.stop="close">取&nbsp;&nbsp;&nbsp;&nbsp;消</el-button>
            <el-button size="small" class="commit_bt" :loading="showLoading" @click.stop="doCommitAction">确&nbsp;&nbsp;&nbsp;&nbsp;定</el-button>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "@/components/CommonDialog";
export default {
  data() {
    return {
      //宽度
      width: "300px",

      //加载框值
      showLoading: false,
    };
  },
  created() {
    console.log(`接受到的type=${this.type},校区id=${this.resetSchoolId},老师id=${this.resetTeacherId}`);
  },
  mounted() {},

  methods: {
    //确定的操作
    doCommitAction() {
      //加载
      this.showLoading = true;

      //请求重置密码的接口
      let param = this.getHttpParams();

      //重置密码的请求URL
      let url = this.type == 1 ? this.$api.SCHOOLRESTPWD : this.$api.TEACHERRESTPWD;

      //接口请求的type值
      param.type = this.type == 1 ? 9 : 13;

      if (this.type == 1) {
        //校区修改校长
        param.school_id = this.resetSchoolId;
      } else if (this.type == 2) {
        //修改老师密码
        param.tid = this.resetTeacherId;
      }
      this.$http
        .fetchPost(url, param)
        .then((res) => {
          let result = res.data;
          this.showLoading = false;
          if (result.state == 200) {
            //重置密码成功
            this.showSuccessMsg("重置密码成功");
            this.close();
          } else {
            this.showErrorMsg(`${result.msg},重置密码失败`);
          }
        })
        .catch((err) => {
          this.showLoading = false;
          console.log("请求数据异常");
        });
    },

    //取消的操作
    doCancelAction() {
      //返回
      this.close();
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },
  },
  watch: {},
  computed: {},
  components: { CommonDialog },
  //type 1是重置校区的账号密码  2是重置老师的账号密码
  props: ["showDialog", "title", "resetSchoolId", "resetTeacherId", "type"],
};
</script>
<style lang="scss" scoped>
.reset_pwd {
  ::v-deep .el-dialog {
    top: 50%;
  }

  .content {
    width: 100%;

    .reset_info {
      font-size: 12px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .bom {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
    }

    .commit_bt {
      margin-left: 40px;
      color: white;
      background: #409eff;
    }
  }
}
</style>
