// 新增教师的弹框
<template>
  <div class="add_teacher">
    <CommonDialog @close="close" :showDialog="showDialog" :title="title" :width="width">
      <template v-slot:content>
        <div class="real_content">
          <!-- 加载框 -->
          <Loading v-if="showLoading"></Loading>
          <!-- 内容 -->
          <div class="add_teacher_dialog_content" v-else>
            <!-- 列表布局 -->
            <div class="course_list">
              <!-- 学校名称 -->
              <div class="margin_top_10">
                <div>
                  <span class="red_icon">*</span>
                  <span>教师姓名：</span>
                  <span>
                    <el-input class="name_input" clearable v-model="teacherName" placeholder="请输入姓名" maxlength="20" size="small"></el-input>

                    <span class="red_icon margin_left_20">*</span>
                    <span>教师手机号：</span>
                    <span> <el-input class="input" clearable v-model="phoneNumber" placeholder="请输入手机号" maxlength="11" size="small"></el-input></span
                  ></span>
                </div>
              </div>

              <!-- 所在校区 -->
              <div class="margin_top_10" v-if="role != 3">
                <div>
                  <span class="red_icon">*</span>
                  <span>所在校区：</span>
                  <span>
                    <el-input
                      class="name_input"
                      clearable
                      v-model="schoolName"
                      placeholder="请输入校区编码或者校区名称"
                      maxlength="20"
                      size="small"
                      @input="isSchool()"
                    ></el-input
                  ></span>
                </div>
              </div>

              <!-- 允许老师自主从学生库拉学生进班级 -->
              <div class="margin_top_10">
                <div>
                  <span>允许老师自主从学生库拉学生进班级</span>
                  <span>
                    <el-switch class="margin_left_20" v-model="isLimPeople"> </el-switch>
                  </span>
                </div>
              </div>

              <!-- 课程访问权限 -->
              <div class="item margin_top_10">
                <div>
                  <!-- <span class="red_icon">*</span> -->
                  <span>课程访问权限：</span>

                  <div class="select_list" v-show="isCoureseManager">
                    <div class="item" v-for="(item, index) in CourseList" :key="index">
                      <span
                        :class="{
                          selected: CourseList[index].state == 1,
                          unselected: CourseList[index].state == 0,
                          noneselect: CourseList[index].state == -1,
                        }"
                        @click.stop="selectCourse(index)"
                      >
                        {{ item.subName }}
                      </span>

                      <span v-for="(item, k) in CourseList[index].levelList" :key="k">
                        <span
                          :class="{
                            item_selected: CourseList[index].levelList[k].state == 1,
                            item_unselected: CourseList[index].levelList[k].state == 0,
                            item_noneselect: CourseList[index].levelList[k].state == -1,
                          }"
                          @click="selectCourseItem(index, k)"
                          >{{ item.levelName }}</span
                        >
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 提示 -->
              <div class="margin_top_10">
                <div>
                  <span class="margin_left_30"></span>
                  <span>注：（如需开通，请选中需开通的课程）</span>
                  <span> </span>
                </div>
              </div>
            </div>
            <!-- 底部的取消和确定按钮 -->
            <div class="bom_action">
              <!-- 取消 -->
              <div class="cancel" @click.stop="close"><span>取</span><span style="margin-left: 16px">消</span></div>
              <!-- 确定 -->
              <el-button :loading="showCommitLoading" :class="['commit']" @click="doCommitAction">
                <span>确</span><span style="margin-left: 16px">定</span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="empty_background" v-if="isShowLoading">
          <div class="studialog_content">
            <!-- 标题 -->
            <div class="top">
              <span class="com_title">提示</span>
              <!-- <i class="iconfont cloud-guanbi" @click.stop="close"></i> -->
              <i class="el-icon-close" style="margin-right: 7px" @click.stop="closeDialog"></i>
            </div>
            <!-- 间隔线 -->
            <el-divider></el-divider>

            <!-- 提示信息部分 -->
            <div class="msg_info">
              <span class="course_Text1">
                确认给{{ teacherName }}开通<span class="course_Text">{{ courseListName }}</span
                >的课程访问权限吗?
              </span>
            </div>

            <div class="msg_tips">
              注:点击确认会扣除<span class="course_Text">{{ courseQuota }}</span
              >个教师账户数
            </div>

            <!-- 底部 -->
            <div class="com_dv margin_top_30">
              <!-- 底部的取消和保存按钮 -->
              <div class="bom">
                <!-- 取消 com_bt_action -->
                <el-button size="medium" class="commit_bt" @click.stop="closeDialog">取&nbsp;&nbsp;消</el-button>

                <!-- 保存 -->
                <el-button size="medium" class="commit_bt" type="primary" @click.stop="doSaveAction">确&nbsp;&nbsp;定</el-button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </CommonDialog>
  </div>
</template>
<script>
//引入通用Dialog对话框
import CommonDialog from "../../components/CommonDialog";
export default {
  data() {
    return {
      //校长扣除的教师账户数
      courseQuota: "1",
      courseListName: "",
      //允许老师自主从学生库拉学生进班级
      isLimPeople: false,
      //权限json
      levelInfo: "",
      Sid: "",
      //宽度
      width: "720px",
      //是否显示确定按钮加载框的标识
      showCommitLoading: false,
      //显示加载框
      showLoading: false,
      courseData: [],
      //教师姓名
      teacherName: "",
      //手机号
      phoneNumber: "",
      //校区名或编码
      schoolName: "",
      //权限集合
      CourseList: [],
      //是否有课程权限
      isCoureseManager: false,
      //是否显示loading
      isShowLoading: false,
      // 正则验证 手机号
      numReg: /^1[3456789]\d{9}$/,
      role: "-1",
    };
  },
  created() {
    this.role = this.getUserRole();
  },
  mounted() {
    //判断是否校长端
    if (this.getUserRole() == 3) {
      this.schoolName = this.getUserInfo().schoolId;
      this.isSchool();
    }
  },
  methods: {
    //关闭的方法
    closeDialog() {
      this.isShowLoading = false;
    },

    //确定添加
    doSaveAction() {
      this.isShowLoading = false;
      this.showCommitLoading = true;
      this.httpUpload();
    },
    //验证学校是否存在
    isSchool() {
      console.log(this.schoolName);
      if (this.isEmpty(this.schoolName)) {
        this.isCoureseManager = false;
      } else {
        let param = this.getHttpParams();
        param.type = 2;
        param.sid = this.schoolName;

        this.$http
          .fetchPost(this.$api.TEACHERMANAGER, param)
          .then((res) => {
            let resulte = res.data;
            if (resulte.state == 200) {
              //有学校
              console.log(resulte.data.sName);
              this.isCoureseManager = true;
              this.Sid = resulte.data.sid;
              //获取课程权限
              this.getCourseList();
            } else if (resulte.state == 501) {
              //学校不存在
              console.log("学校不存在");
              this.Sid = "";
              this.isCoureseManager = false;
            }else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
          })
          .catch((err) => {
            // this.showLoading = 1;
            console.log("请求数据异常");
          });
      }
    },
    //获取课程课授权的权限
    getCourseList() {
      let param = this.getHttpParams();
      param.type = 3;
      param.sid = this.Sid;

      this.$http
        .fetchPost(this.$api.TEACHERMANAGER, param)
        .then((res) => {
          let resulte = res.data;
          if (resulte.state == 200) {
            //请求成功
            console.log(resulte);

            this.CourseList = resulte.data.subList;
          } else {
            this.showErrorMsg(this.getMesage(res.data.state));
          }
        })
        .catch((err) => {
          console.log("请求数据异常");
        });
    },

    //选中科目 状态（-1余额不足，0未开启，1存在已开启级别）
    selectCourse(index) {
      console.log("选中科目" + index);
      if (this.CourseList[index].state == 0) {
        
          this.CourseList[index].state = 1;
        
        // this.CourseList[index].levelList.forEach((element) => {
        //   element.state = 1;
        // });
        if(this.CourseList[index].levelList[0].state!=-1){
        this.CourseList[index].levelList[0].state = 1;
        }
      } else if (this.CourseList[index].state == 1) {
        var temp = false;
        this.CourseList[index].levelList.forEach((element) => {
          if (element.state == 0) {
            element.state = 1;
            temp = true;
          }
        });
        if (!temp) {
          this.CourseList[index].state = 0;
          
          this.CourseList[index].levelList.forEach((element) => {

            if(element.state!=-1){
            element.state = 0;
            }
          });
        }
      }
    },
    //选中级别 状态（-1余额不足，0未开启，1存在已开启级别）
    selectCourseItem(index, k) {
      event.stopPropagation();
      console.log("选中级别" + index + "k=" + k);
      if (this.CourseList[index].levelList[k].state == 0) {
        this.CourseList[index].levelList[k].state = 1;
        this.CourseList[index].state = 1;
      } else if (this.CourseList[index].levelList[k].state == 1) {
        this.CourseList[index].levelList[k].state = 0;

        var isSelect = true;
        this.CourseList[index].levelList.forEach((element) => {
          if (element.state == 1) {
            isSelect = false;
          }
        });

        if (isSelect) {
          this.CourseList[index].state = 0;
        }
      }
    },

    //关闭弹框
    close() {
      //触发父组件的关闭方法
      this.$emit("close");
    },
    //确定的操作
    doCommitAction() {
      if (this.isEmpty(this.teacherName)) {
        this.showWarnMsg("教师姓名不能为空");

        return;
      }
      if (this.isEmpty(this.phoneNumber)) {
        this.showWarnMsg("手机号码不能为空");

        return;
      }

      if (this.isEmpty(this.Sid)) {
        this.showWarnMsg("输入校区不存在");

        return;
      }
      if (!this.numReg.test(this.phoneNumber)) {
        this.showWarnMsg("手机号格式不正确");
        return;
      }

      //选中课程
      this.courseListName = "";

      console.log(this.CourseList);
      var temp = 0;
      this.CourseList.forEach((element) => {
        if (element.state == 1) {
          if (temp == 0) {
            this.courseListName += element.subName + "";
            temp = 1;
          } else {
            this.courseListName += "、" + element.subName;
          }
        }
      });

      if (this.isEmpty(this.courseListName)) {
        //课程访问权限为空时
        this.httpUpload();
        return;
      }
      //获取扣除的账户数
      this.getQuota();
      //弹框提示
      // this.isShowLoading = true;
    },

    //获取扣除账户数的接口
    getQuota() {
      this.levelInfo = JSON.stringify(this.CourseList);

      let param = this.getHttpParams();
      param.type = 14;
      param.sid = this.Sid;
      param.tel = this.phoneNumber;
      param.levelInfo = this.levelInfo;
      param.tid = "-1";

      this.$http.fetchPost(this.$api.TEACHERMANAGER, param).then((res) => {
        let resulte = res.data;
        this.showCommitLoading = false;
        if (resulte.state == 200) {
          this.courseQuota = resulte.data;
          //弹框提示
          this.isShowLoading = true;
        } else if (resulte.state == 201) {
          //直接将服务器返回的错误内容弹出提示
          this.showWarnMsg("手机号格式不正确");
        } else {
          //直接将服务器返回的错误内容弹出提示
          this.showErrorMsg(this.getMesage(res.data.state));
        }
      });
    },
    //保存接口
    httpUpload() {
      this.levelInfo = JSON.stringify(this.CourseList);

      let param = this.getHttpParams();
      param.type = 4;
      param.sid = this.Sid;
      param.tel = this.phoneNumber;
      param.name = this.teacherName;
      param.levelInfo = this.levelInfo;
      param.sign = "0";
      if (this.isLimPeople) {
        //（0不允许，1允许）
        param.addStudent = 1;
      } else {
        param.addStudent = 0;
      }
      param.tid = "-1";
      this.$http.fetchPost(this.$api.TEACHERMANAGER, param).then((res) => {
        let resulte = res.data;
        if (resulte.state == 200) {
          if (this.isEmpty(this.courseListName)) {
            this.showSuccessMsg("账号创建成功，请给老师添加课程访问权限吧！");
          } else {
            this.showSuccessMsg("保存成功");
          }

          this.$emit("close");
          this.$emit("updatePage");
        } else {
          //直接将服务器返回的错误内容弹出提示
          this.showErrorMsg(this.getMesage(res.data.state));
          this.showCommitLoading = false;
        }
      });
    },

    //获取传递选中课程的数据
    getSubIdList() {
      let selectedCourse = this.courseData.filter((item) => item.subjectState == 1);
      let subIdsArr = selectedCourse.map((obj) => {
        return obj.subjectId;
      });

      return subIdsArr.join(",");
    },
  },
  computed: {},
  components: {
    CommonDialog,
  },
  props: ["showDialog", "title"],
};
</script>
<style lang="scss" scoped>
.add_teacher {
  ::v-deep .el-dialog {
    top: 50%;
    // background: #eff2f7;
  }

  .real_content {
    .add_teacher_dialog_content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: relative;
      .course_list {
        margin-left: 5%;
        min-height: 200px;

        .name_input {
          margin-left: 0%;
          width: 35%;
        }
        .input {
          margin-left: 2%;
          width: 30%;
        }
        .select_list {
          margin-left: 16%;
          margin-top:-10px;
          padding-top: 10px;
          overflow-y: scroll;
          height: calc(40vh - 6px);
        }
        .item {
          min-height: 45px;
          width: 100%;
        }
        .item_noneselect {
          background-color: #cccccc;
          padding: 5px;
          border-radius: 5px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          // cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .noneselect {
          background-color: #cccccc;
          padding: 8px;
          border-radius: 10px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;

          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .selected {
          background-color: #f39800;
          border-radius: 10px;
          width: 120px;
          padding: 10px;
          color: #fff;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .unselected {
          background-color: #ffffff;
          padding: 8px;
          border-radius: 10px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }
        .unselected:hover {
          background-color: #ffffff;
          border-radius: 10px;
          width: 120px;
          padding: 8px;
          color: #f39800;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
        }

        .item_selected {
          background-color: #f39800;
          border-radius: 5px;
          width: 8px;
          padding: 5px;
          color: #fff;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .item_unselected {
          background-color: #ffffff;
          padding: 5px;
          border-radius: 5px;
          width: 120px;
          border-style: solid;
          border-width: 1px;
          border-color: gray;
          cursor: pointer;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
        .item_unselected:hover {
          background-color: #ffffff;
          border-radius: 5px;
          width: 120px;
          padding: 5px;
          color: #f39800;
          border-style: solid;
          border-width: 1px;
          border-color: #f39800;
          margin-top: 10px;
          // margin-left: 20%;
          text-align: center;
          margin-left: 10px;
          font-size: 10px;
        }
      }

      //底部的操作按钮
      .bom_action {
        margin-top: 20px;
        height: 48px;
        display: flex;
        justify-content: center;

        .cancel,
        .commit {
          width: 120px;
          height: 38px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 4px;
          cursor: pointer;
        }

        .cancel {
          background: #dcdcdc;
          margin-right: 30px;
          color: black;
        }

        .commit {
          background: #169bd5;
          // background: #70B600;
          color: white;
        }
      }

      //操作框
      .inner_dialog {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba($color: #000000, $alpha: 0.4);
      }
    }
  }

  .studialog_content {
    position: absolute;
    min-height: 280px;
    min-width: 500px;
    margin-left: 16%;
    margin-top: 10%;
    background: #efefef;
    border-radius: 6px;

    border-width: 3px;

    .top {
      display: flex;
      margin: 12px 0;
      // height: 58px;
      align-items: center;
      position: relative;

      .com_title {
        color: black;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        font-size: 16px;
        font-weight: bold;
      }

      .cloud-guanbi {
        position: absolute;
        cursor: pointer;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 16px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .com_dv {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex;
      padding-bottom: 20px;
    }

    .msg_info {
      margin-top: 45px;
      display: flex;

      justify-content: center;
      align-items: center;
      font-size: 14px;
      width: 90%;
      margin-left: 5%;
      .course_Text1 {
        font-weight: bolder;
        font-size: 16px;
        line-height: 32px;
      }
      .course_Text {
        color: #166bd5;
        font-weight: bolder;
      }
    }
    .msg_tips {
      margin-top: 20px;
      display: flex;
      margin-left: 10%;
      font-size: 18px;
      color: black;
      font-weight: bolder;
      .course_Text {
        color: red;
        font-weight: bold;
      }
    }
    .commit_bt {
      height: 35px;
      width: 120px;
      // position: absolute;
      // bottom: 14px;
    }
  }
  .bg_background {
    position: absolute;
    height: 100%;
    width: 100%;
  }
  .empty_background {
    opacity: 0.95;
    background: #888888;
    position: absolute;
    height: 100%;
    width: 100%;

    // background: #F00;
  }
}
</style>